<template>
    <!--navigation-->
    <div v-if="total_page > 1" class="products-footer tc mt__40">
        <nav class="nt-pagination w__100 tc paginate_ajax">
            <ul class="pagination-page page-numbers">
                <li v-if="page > 1"><a @click.prevent="editPage(parseInt(page) - 1)" class="prev page-numbers" href="#">上一页</a></li>
                <li><a @click.prevent="editPage(1)" :class="['page-numbers', {'current': page == 1}]" href="#">1</a></li>
                <li v-if="page >= 5"><span class="page-numbers">...</span></li>
                <template v-for="num in page_list" :key="num">
                    <li><a @click.prevent="editPage(num)" href="#" :class="['page-numbers', {'current': page == num}]">{{num}}</a></li>
                </template>
                <li v-if="(total_page > 8) && (parseInt(page) + 3 < total_page)"><span class="page-numbers">...</span></li>
                <li v-if="(total_page > 7) && (parseInt(page) + 2 < total_page)"><a @click.prevent="editPage(total_page)" href="#" class="page-numbers">{{total_page}}</a></li>
                <li v-if="page != total_page && total_page != 0"><a @click.prevent="editPage(parseInt(page) + 1)" class="next page-numbers" href="#">下一页</a></li>
            </ul>
        </nav>
    </div>
    <!--end navigation-->
</template>

<script>
import {computed, reactive} from "vue";

export default {
    name: "Pagination",
    props:['count', 'page', 'page_size', 'is_select_page_size', 'editPage', 'editPageSize'],
    setup(props) {

        const options = reactive({
            title: '分页',
            page_size: props.page_size || 10,
        });

        // 分页开始
        const total_page = computed(function() {
            if (Number(props.count) == 0 || Number(props.page_size) >= Number(props.count)) {
                return 1;
            } else {
                return (parseInt(props.count) % parseInt(props.page_size) == 0) ? (parseInt(props.count) / parseInt(props.page_size)) : Math.ceil(parseInt(props.count) / parseInt(props.page_size));
            }
        });
        const page_list = computed(function () {
            let arr = [];

            if (total_page.value <= 7) {
                for (let i = 0; i < (total_page.value - 1); i++) {
                    arr.push(i + 2);
                }
            } else {
                if (Number(props.page) >= 5) {
                    if (total_page.value - parseInt(props.page) < 2) {
                        arr = [total_page.value - 4, total_page.value - 3, total_page.value - 2, total_page.value - 1, total_page.value];
                    } else {
                        arr = [parseInt(props.page) - 2, parseInt(props.page) - 1, props.page, parseInt(props.page) + 1, parseInt(props.page) + 2];
                    }
                } else {
                    arr = [2, 3, 4, 5, 6, 7];
                }
            }

            return arr;
        });
        // 分页结束

        return {options, total_page, page_list}
    },
}
</script>

<style scoped>

</style>