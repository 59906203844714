<template>
    <div class="col-lg-3 order-12 col-xs-12 sidebar">
        <div class="kalles-section type_instagram">
            <div class="cat_shop_wrap mb__60">
                <div class="cat_fixcl-scroll">
                    <div class="cat_fixcl-scroll-content css_ntbar">
                        <div class="row wrap_filter">
                            <div class="col-12 col-md-12 widget widget_product_categories cat_count_true nt_filter_block">
                                <h5 class="widget-title">分类</h5>
                                <ul class="product-categories">
                                    <template v-for="(dt, index) in article_category_list" :key="index">
                                        <li :class="['cat-item', {'current-cat': $route.params.id == dt.article_category_id}]"><a :href="'/article/list/' + dt.article_category_id">{{dt.title}}<span class="cat_count">(10)</span></a></li>
                                    </template>
                                </ul>
                            </div>
                            <div v-if="false" class="col-12 col-md-12 widget widget_post_list">
                                <h5 class="widget-title">Recent Post</h5>
                                <div class="post_list_widget">
                                    <div class="row mb__10 pb__10">
                                        <div class="col-auto widget_img_ar"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201920%201281%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="Spring – Summer Trending 2020" data-srcset="/images/blog-page/blog-thumbnail-01.jpg"></a></div>
                                        <div class="col widget_if_ar"><a class="article-title db" href="blog-post-with-instagram-shop.html">Spring – Summer Trending 2020</a>
                                            <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </div>
                                    </div>
                                    <div class="row mb__10 pb__10">
                                        <div class="col-auto widget_img_ar"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201920%201281%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="The Easiest Way to Break Out on Top" data-srcset="/images/blog-page/blog-thumbnail-02.jpg"></a></div>
                                        <div class="col widget_if_ar"><a class="article-title db" href="blog-post-with-instagram-shop.html">The Easiest Way to Break Out on Top</a>
                                            <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </div>
                                    </div>
                                    <div class="row mb__10 pb__10">
                                        <div class="col-auto widget_img_ar"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201920%201280%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="Style for couple in Weeding season" data-srcset="/images/blog-page/blog-thumbnail-03.jpg"></a></div>
                                        <div class="col widget_if_ar"><a class="article-title db" href="blog-post-with-instagram-shop.html">Style for couple in Weeding season</a>
                                            <time datetime="2020-04-06T02:17:00Z">April 6, 2020</time>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-12 col-md-12 widget widget_instagram">
                                <h5 class="widget-title">Instagram</h5>
                                <div class="nt_instagram_wrap row equal_nt ins_spaces_2 ins_rounded_0 nt_cover ratio1_1 position_8 js_nt_ist">
                                    <ul class="kalles-instagram-grid">
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_1.jpg" alt="kalles ins 01"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_2.jpg" alt="kalles ins 02"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_4.jpg" alt="kalles ins 03"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_5.jpg" alt="kalles ins 04"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_6.jpg" alt="kalles ins 05"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_7.jpg" alt="kalles ins 06"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_8.jpg" alt="kalles ins 07"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_13.jpg" alt="kalles ins 08"></a></li>
                                        <li class="kalles-instagram-grid__elm"><a href="index.html" class="kalles-instagram-grid__thumb-link"><img class="lazyload img-ints" src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22360px%22%20height%3D%22360px%22%20viewBox%3D%220%200%20100%20100%22%20preserveAspectRatio%3D%22xMidYMid%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M24.3%2C30C11.4%2C30%2C5%2C43.3%2C5%2C50s6.4%2C20%2C19.3%2C20c19.3%2C0%2C32.1-40%2C51.4-40%20C88.6%2C30%2C95%2C43.3%2C95%2C50s-6.4%2C20-19.3%2C20C56.4%2C70%2C43.6%2C30%2C24.3%2C30z%22%20stroke%3D%22%2356cfe1%22%20stroke-width%3D%222%22%20stroke-dasharray%3D%22205.271142578125%2051.317785644531256%22%3E%3Canimate%20attributeName%3D%22stroke-dashoffset%22%20calcMode%3D%22linear%22%20values%3D%220%3B256.58892822265625%22%20keyTimes%3D%220%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%3E%3C%2Fanimate%3E%3C%2Fpath%3E%3C%2Fsvg%3E" width="360" height="360" data-src="/images/instagram/ins1_14.jpg" alt="kalles ins 09"></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="false" class="col-12 col-md-12 widget widget_product_list">
                                <h5 class="widget-title">Sale Products</h5>
                                <div class="product_list_widget">
                                    <div class="row mb__10 pb__10">
                                        <div class="col widget_img_pr"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201128%201439%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="Analogue Resin Strap" data-srcset="/images/blog-page/pr-thumbnail-01.jpg"></a></div>
                                        <div class="col widget_if_pr"><a class="product-title db" href="product-detail-layout-01.html">Analogue Resin Strap</a>$30.00 </div>
                                    </div>
                                    <div class="row mb__10 pb__10">
                                        <div class="col widget_img_pr"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201128%201439%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="Ridley High Waist" data-srcset="/images/blog-page/pr-thumbnail-02.jpg"></a></div>
                                        <div class="col widget_if_pr"><a class="product-title db" href="product-detail-layout-01.html">Ridley High Waist</a>$36.00 </div>
                                    </div>
                                    <div class="row mb__10 pb__10">
                                        <div class="col widget_img_pr"><a class="db pr oh" href="product-detail-layout-01.html"><img src="data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%201128%201440%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3C%2Fsvg%3E" class="w__100 lz_op_ef lazyload" alt="Blush Beanie" data-srcset="/images/blog-page/pr-thumbnail-03.jpg"></a></div>
                                        <div class="col widget_if_pr"><a class="product-title db" href="product-detail-layout-01.html">Blush Beanie</a>$15.00 </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="false" class="col-12 col-md-12 widget widget_text">
                                <h5 class="widget-title">Blog Tags</h5>
                                <div class="loke_scroll">
                                    <ul class="nt_filter_block nt_filter_styletag blg_count_true">
                                        <li><a href="#">Beauty <span class="blg_count">(3)</span></a></li>
                                        <li><a href="#">Fashion <span class="blg_count">(5)</span></a></li>
                                        <li><a href="#">Food <span class="blg_count">(3)</span></a></li>
                                        <li><a href="#">Life <span class="blg_count">(1)</span></a></li>
                                        <li><a href="#">Life Style <span class="blg_count">(5)</span></a></li>
                                        <li><a href="#">Travel <span class="blg_count">(5)</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    name: "Sidebar",
    setup() {
        const article_category_list = inject('article_category_list');

        return {article_category_list}
    },
}
</script>

<style scoped>

</style>