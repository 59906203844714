<template>
    <div id="nt_wrapper">

        <Header/>

        <div id="nt_content">

            <!--shop banner-->
            <div class="kalles-section page_section_heading">
                <div class="page-head tc pr oh cat_bg_img page_head_">
                    <div class="parallax-inner nt_parallax_false lazyload nt_bg_lz pa t__0 l__0 r__0 b__0" data-bgset="/img/banner-009.jpeg"></div>
                    <div class="container pr z_100">
                        <h1 class="mb__5 cw">{{article_category.title}}</h1>
                        <p class="mg__0" v-html="article_category.description"></p>
                    </div>
                </div>
            </div>
            <!--end shop banner-->

            <!--main content-->
            <div class="container container_cat cat_default mt__60 mb__20">
                <div class="row nt_single_blog">
                    <Sidebar/>
                    <div class="col-lg-9 order-1 col-xs-12">
                        <div class="kalles-section nt_section type_isotope">

                            <!--articles-->
                            <div class="articles articles art_des2 nt_articles_holder row des_cnt_1 nt_cover ratio4_3 position_8 equal_nt">
                                <article v-for="(dt, index) in article_list" :key="index" class="post_nt_loop post_1 col-lg-6 col-md-6 col-12 mb__40">
                                    <a class="mb__20 db pr oh" :href="'/article/item/' + dt.article_id" target="_blank">
                                        <div class="lazyload nt_bg_lz pr_lazy_img" :data-bgset="dt.img_url ? dt.img_url : '/img/420x315.jpg'"></div>
                                    </a>
                                    <div class="post-info mb__5" style="font-size: 12px;">
                                        <span v-if="dt.author" class="post-author mr__15">作者：<span v-if="dt.author_url" class="cd">{{dt.author}}</span><a :href="dt.author_url" class="cd">{{dt.author_url}}</a></span>
                                        <span v-if="dt.category" class="post-author mr__15">分类：<a :href="'/article/list/' + dt.article_category_id" class="cd">{{dt.category.title}}</a></span>
                                        <span class="post-time">时间：<span class="cd"><time :datetime="dt.created_time">{{dt.created_time}}</time></span></span>
                                        <h4 class="mg__0 fs__16 mt__15 ls__0"><a class="cd chp open" :href="'/article/item/' + dt.article_id" target="_blank">{{dt.title}}</a></h4>
                                    </div>
                                </article>
                            </div>
                            <!--end articles-->

                            <Pagination :count="options.search_data.count" :page="options.search_data.page" :page_size="options.search_data.page_size" :editPage="editPage" :editPageSize="editPageSize" />

                        </div>
                    </div>
                </div>
            </div>
            <!--end main content-->

        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Pagination from '@/components/Pagination.vue'
import Sidebar from './components/Sidebar.vue'
import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import '/public/css/blog.css'
import {xsListArticleV1Api} from "@/api/article/article";

export default {
    name: 'ArticleList',
    components: {Header, Footer, Pagination, Sidebar},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '产品',
            name: proxy.$route.name,
            url: proxy.$route.path,
            show_cat_open: true,
            search_data: {
                article_id: "",
                article_category_id: proxy.$route.params.id || '',
                title: proxy.$route.query.search_keyword || '',
                sort_by: [],
                recommends: [],
                tops: [],
                page: proxy.$route.query.page || 1,
                page_size: proxy.$route.query.page_size || 8,
                count: 0,
            },
        });

        const article_category = ref({
            title: '新闻中心'
        });

        const article_category_list = inject('article_category_list');
        if (proxy.$route.params.id && article_category_list.value && article_category_list.value.length > 0) {
            article_category.value = article_category_list.value.find(o => o.article_category_id == proxy.$route.params.id);
        }

        const article_list = ref([]);
        const apiArticleList = () => {
            return new Promise(function (resolve) {
                xsListArticleV1Api(options.search_data).then(res => {
                    if (res.data.code == 0) {
                        article_list.value = res.data.data.list;
                        article_list.value.forEach(function(val) {
                            val.category = article_category_list.value.find(o => o.article_category_id == val.article_category_id);
                        });

                        options.search_data.count = res.data.data.count;
                    }

                    resolve(true);
                });
            });
        };

        const editPage = (page) => {
            options.search_data.page = page;

            apiArticleList();
        };
        const editPageSize = (page_size) => {
            options.search_data.page = 1;
            options.search_data.page_size = page_size;

            apiArticleList();
        };

        // 初始数据加载
        Promise.all([apiArticleList()]).then(() => {
            // apiarticleList().then(() => {
            //     mainNotyConfirm.close();
            // });
        });

        // watch(article_category_list, () => {
        //     article_category.value = article_category_list.value.find(o => o.article_category_id == proxy.$route.params.id);
        // });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-article-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {options, website, article_list, article_category, article_category_list, editPage, editPageSize}
    },
}
</script>

<style scoped>

</style>